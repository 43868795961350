/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../app/modules/AuthCognito/_redux/authActions";
import { GetTenant } from "../../../../../app/utils";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_helpers";

export function QuickUser() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, tenant } = useSelector(
    (state) => ({
      user: state.auth.user,
      tenant: GetTenant(),
    }),
    shallowEqual
  );

  const logoutClick = () => {
    dispatch(actions.logout()).then(() => {
      dispatch({ type: "USER_LOGOUT" });
      localStorage.clear();
      history.push("/");
    });
  };

  return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            <FormattedMessage id="USER.PROFILE" />
            {/*<small className="text-muted font-size-sm ml-2">12 messages</small>*/}
          </h3>
          <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"/>
          </a>
        </div>

        <div
          className="offcanvas-content pr-5 mr-n5"
        >
          <div className="d-flex align-items-center mt-5">
            <div
                className="symbol symbol-100 mr-5"
            >
              <div className="symbol-label" style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/default.jpg"
                )})`
              }}/>
              <i className="symbol-badge bg-success"/>
            </div>
            <div className="d-flex flex-column">
              <a
                  href="/user/profile"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {user ? ((user.firstName ? user.firstName : "") + " " + (user.lastName ? user.lastName : "")) : ""}
              </a>
              <div className="navi mt-2">
                <a href="/user/profile" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="fas fa fa-envelope text-primary"></span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user?.email || user?.mobile}
                  </span>
                </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>
                <FormattedMessage id="USER.SIGN_OUT" />
              </button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5"/>

          <div className="navi navi-spacer-x-0 p-0">
            <div onClick={() => history.push("/user/profile")} className="navi-item cursor-pointer">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="fas fa fa-clipboard text-success"></span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="USER.MY_PROFILE" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="USER.MY_PROFILE_DESCRIPTION" />
                  </div>
                </div>
              </div>
            </div>

            {/*
            <div onClick={() => history.push("/news")} className="navi-item cursor-pointer">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                          "/media/svg/icons/General/Notifications1.svg"
                      )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="SERVICE.NEWS" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="SERVICE.NEWS_AND_CHANGELOG" />
                  </div>
                </div>
              </div>
            </div>
            */}

            {tenant && tenant.isPartner && (
              <>
                <div onClick={() => history.push("/changelog")} className="navi-item cursor-pointer">
                  <div className="navi-link">
                    <div className="symbol symbol-40 bg-light mr-3">
                      <div className="symbol-label">
                      <span className="fas fa fa-terminal text-primary"></span>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold">
                        <FormattedMessage id="SERVICE.CHANGELOG" />
                      </div>
                      <div className="text-muted">
                        <FormattedMessage id="SERVICE.SERVICES_CHANGELOG" />
                      </div>
                    </div>
                  </div>
                </div>

                <div onClick={() => history.push("/docs")} className="navi-item cursor-pointer">
                  <div className="navi-link">
                    <div className="symbol symbol-40 bg-warning mr-3">
                      <div className="symbol-label">
                      <span className="fas fa fa-print text-warning"></span>
                      </div>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold">
                        <FormattedMessage id="SERVICE.DOCS" />
                      </div>
                      <div className="text-muted">
                        <FormattedMessage id="SERVICE.MANUALS_MSG" />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
  );
}
