export const lightSearchRetailStoreLicenses = /* GraphQL */ `
  query SearchRetailStoreLicenses(
    $filter: SearchableRetailStoreLicenseFilterInput
    $sort: SearchableRetailStoreLicenseSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRetailStoreLicenses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        firmware
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        retailStore {
          id
          name
          partnerId
          partner {
            id
            companyName
            companyVat
          }
          tenant {
            id
            companyName
            companyVat
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const listPrivilegeGroups = /* GraphQL */ `
  query ListPrivilegeGroups(
    $filter: ModelPrivilegeGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivilegeGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        privileges {
          items {
            id
            privilegeId
            privilegeGroupId
            privilege {
              id
              name
              description
              ability
              entity
              license {
                id
                name
                commercialName
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            privilegeGroup {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listPrivileges = /* GraphQL */ `
  query ListPrivileges(
    $filter: ModelPrivilegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivileges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        ability
        entity
        license {
          id
        }
      }
      nextToken
    }
  }
`;

export const tenantUsersByTenant = /* GraphQL */ `
  query TenantUsersByTenant(
    $tenantId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTenantUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantUsersByTenant(
      tenantId: $tenantId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        tenantId
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          createdAt
          updatedAt
          tenantUser {
            items {
              id
              userId
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
          mobile
          email
          zipCode
          county
          municipality
          address
          country
        }
        user {
          id
          enabled
          tenantDefault
          retailStoreDefault
          userPrivilege {
            items {
              id
              userId
              privilegeId
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
          userRetailStore {
            items {
              id
              userId
              retailStoreId
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
          showTutorial
          email
          mobile
          pushNotificationTokens {
            service
            deviceToken
          }
          deleted
          createdAt
          updatedAt
          tenantUser {
            items {
              id
              userId
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
          userLicense {
            items {
              id
              tenantId
              userId
              licenseId
              validFrom
              validTo
              createdAt
              updatedAt
            }
            nextToken
          }
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      enabled
      logo
      shortCode
      isPartner
      partnershipStartDate
      isServiceManager
      nextItemSorting
      nextItemGroupSorting
      defaultPartnerCode
      companyName
      companyVat
      firstUserId
      firstRetailStoreId
      userCount
      moitoIotCount
      retailStoreCount
      cashRegisterCount
      rtUtilityInactive
      currentRtUtilityCategory
      partnerText
      partnerTosAccepted
      privacyPolicyAcceptedDate
      isBetaTester
      partnerCashRegisterCounter
      partnerMoitoIotCounter
      extensions
      createdAt
      updatedAt
      mobile
      email
      zipCode
      county
      municipality
      address
      country
      blockLevel
    }
  }
`;

export const listAppSettingss = /* GraphQL */ `
  query ListAppSettingss(
    $filter: ModelAppSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstTenantUserPrivilegeGroups
        createdAt
        updatedAt
        defaultPartner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          createdAt
          updatedAt
        }
        AtorderLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        AtorderDeliveryLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        EInvoiceItLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUserLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUtilityLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUtilityFwDownloadLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUtilityLogsLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUserCollectorLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotAutomaticUpdateLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotMultipleUsersLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotOnlineBackupLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotCollectorLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotDealerLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        MoitoIotCateringLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        RTUtilityEJExtensionLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        CalendarLicense {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        partnerPrivilegeGroup {
          id
          name
          createdAt
          updatedAt
        }
        serviceManagerPrivilegeGroup {
          id
          name
          createdAt
          updatedAt
        }
        lastMoitoIotStableVersion {
          id
          version
          frontendZipPath
          apkPath
          apk21Path
          notes
          isStable
          deleted
          createdAt
          updatedAt
        }
        lastMoitoIotBetaVersion {
          id
          version
          frontendZipPath
          apkPath
          apk21Path
          notes
          isStable
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchTenants = /* GraphQL */ `
  query SearchTenants(
    $filter: SearchableTenantFilterInput
    $sort: SearchableTenantSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTenants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        enabled
        logo
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        privacyPolicyAcceptedDate
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
        blockLevel
      }
      nextToken
      total
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        enabled
        logo
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        privacyPolicyAcceptedDate
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      nextToken
    }
  }
`;

export const tenantsByCompanyVat = /* GraphQL */ `
  query TenantsByCompanyVat(
    $companyVat: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByCompanyVat(
      companyVat: $companyVat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enabled
        logo
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        privacyPolicyAcceptedDate
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      nextToken
    }
  }
`;

export const tenantsByShortCode = /* GraphQL */ `
  query TenantsByShortCode(
    $shortCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByShortCode(
      shortCode: $shortCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enabled
        logo
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        privacyPolicyAcceptedDate
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      nextToken
    }
  }
`;
export const tenantsByDefaultPartnerCode = /* GraphQL */ `
  query TenantsByDefaultPartnerCode(
    $defaultPartnerCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByDefaultPartnerCode(
      defaultPartnerCode: $defaultPartnerCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enabled
        logo
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        privacyPolicyAcceptedDate
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      nextToken
    }
  }
`;

export const lightGetTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      enabled
      defaultPartnerCode
      companyName
      companyVat
      createdAt
      updatedAt
      mobile
      email
      zipCode
      county
      municipality
      address
      country
    }
  }
`;
export const lightTenantsByCompanyVat = /* GraphQL */ `
  query TenantsByCompanyVat(
    $companyVat: String
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tenantsByCompanyVat(
      companyVat: $companyVat
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enabled
        defaultPartnerCode
        companyName
        companyVat
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      nextToken
    }
  }
`;

export const licenseRequestsByPartner = /* GraphQL */ `
  query LicenseRequestsByPartner(
    $partnerId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicenseRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    licenseRequestsByPartner(
      partnerId: $partnerId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        partnerId
        retailStoreId
        duration
        deleted
        extensions
        startFromDate
        level
        isFree
        autorenew
        createdAt
        updatedAt
        tenant {
          id
          enabled
          shortCode
          isPartner
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          extensions
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          createdAt
          updatedAt
        }
        electronicCashRegister {
          id
          tenantId
          retailStoreId
          partnerId
          name
          number
          description
          ejNumber
          ejInitDay
          ejMissingDays
          ejDueDate
          lastZNumber
          fwVersion
          enabled
          deleted
          extensions
          rtUtilityInactive
          lastSyncDatetime
          type
          ecrModel
          version
          tags
          advMessage
          lastZErrorIds
          imageS3Key
          pendingEJExtension
          exportPendingXML
          createdAt
          updatedAt
        }
        moitoIot {
          id
          tenantId
          retailStoreId
          partnerId
          name
          code
          version
          androidApiLevel
          isBetaInstallation
          lastSyncDatetime
          lastBackupS3Key
          lastBackupDatetime
          usersCount
          deleted
          moitoIotInactive
          tags
          firstSyncDone
          extensions
          isFree
          ecrNumber
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const getLicenseRequest = /* GraphQL */ `
  query GetLicenseRequest($id: ID!) {
    getLicenseRequest(id: $id) {
      id
      tenantId
      partnerId
      retailStoreId
      duration
      deleted
      extensions
      startFromDate
      level
      isFree
      autorenew
      createdAt
      updatedAt
      tenant {
        id
        enabled
        shortCode
        isPartner
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      partner {
        id
        enabled
        logo
        shortCode
        isPartner
        partnershipStartDate
        isServiceManager
        nextItemSorting
        nextItemGroupSorting
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        userCount
        moitoIotCount
        retailStoreCount
        cashRegisterCount
        rtUtilityInactive
        currentRtUtilityCategory
        partnerText
        partnerTosAccepted
        privacyPolicyAcceptedDate
        archismallUsername
        archismallPassword
        archismallActiveInvoicesLastDatetime
        archismallPassiveInvoicesLastDatetime
        isBetaTester
        partnerCashRegisterCounter
        partnerMoitoIotCounter
        extensions
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      originalRetailStoreLicense {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        firmware
        createdAt
        updatedAt
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        createdAt
        updatedAt
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
      }
      electronicCashRegister {
        id
        tenantId
        retailStoreId
        partnerId
        name
        number
        description
        ejNumber
        ejInitDay
        ejMissingDays
        ejDueDate
        lastZNumber
        fwVersion
        enabled
        deleted
        extensions
        rtUtilityInactive
        lastSyncDatetime
        type
        ecrModel
        version
        tags
        advMessage
        lastZErrorIds
        imageS3Key
        pendingEJExtension
        exportPendingXML
        createdAt
        updatedAt
      }
      moitoIot {
        id
        tenantId
        retailStoreId
        partnerId
        name
        code
        version
        androidApiLevel
        isBetaInstallation
        lastSyncDatetime
        lastBackupS3Key
        lastBackupDatetime
        usersCount
        deleted
        moitoIotInactive
        tags
        firstSyncDone
        extensions
        isFree
        ecrNumber
        createdAt
        updatedAt
      }
    }
  }
`;
export const retailStoreLicensesByRetailStore = /* GraphQL */ `
  query RetailStoreLicensesByRetailStore(
    $retailStoreId: ID
    $partnerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLicensesByRetailStore(
      retailStoreId: $retailStoreId
      partnerId: $partnerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        firmware
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          extensions
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRetailStoreLicense = /* GraphQL */ `
  query GetRetailStoreLicense($id: ID!) {
    getRetailStoreLicense(id: $id) {
      id
      retailStoreId
      licenseId
      partnerId
      tenantId
      validFrom
      validTo
      assignedToUser
      activatedAt
      number
      electronicCashRegisterId
      moitoIotCode
      moitoIotId
      level
      isFree
      autorenew
      orderConfirmationNotified
      ecrCompanyName
      ecrCompanyVat
      ecrRetailStoreName
      firmware
      createdAt
      updatedAt
      tenant {
        id
        enabled
        logo
        shortCode
        isPartner
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        isBetaTester
        extensions
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      partner {
        id
        enabled
        logo
        shortCode
        isPartner
        defaultPartnerCode
        companyName
        companyVat
        firstUserId
        firstRetailStoreId
        isBetaTester
        extensions
        createdAt
        updatedAt
        mobile
        email
        zipCode
        county
        municipality
        address
        country
      }
      license {
        id
        name
        commercialName
        baseName
        erpItemId
        createdAt
        updatedAt
      }
      retailStore {
        id
        tenantId
        name
        extensions
        deleted
        partnerId
        createdAt
        updatedAt
        zipCode
        county
        municipality
        address
        country
        latitude
        longitude
      }
    }
  }
`;

export const lightRetailStoreLicensesByLicenseRetailStore = /* GraphQL */ `
  query RetailStoreLicensesByLicenseRetailStore(
    $licenseId: ID
    $retailStoreId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLicensesByLicenseRetailStore(
      licenseId: $licenseId
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        firmware
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          isBetaTester
          extensions
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          isBetaTester
          extensions
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const retailStoreLicensesByTenant = /* GraphQL */ `
  query RetailStoreLicensesByTenant(
    $tenantId: ID
    $retailStoreId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRetailStoreLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    retailStoreLicensesByTenant(
      tenantId: $tenantId
      retailStoreId: $retailStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        retailStoreId
        licenseId
        partnerId
        tenantId
        validFrom
        validTo
        assignedToUser
        activatedAt
        number
        electronicCashRegisterId
        moitoIotCode
        moitoIotId
        level
        isFree
        autorenew
        orderConfirmationNotified
        ecrCompanyName
        ecrCompanyVat
        ecrRetailStoreName
        firmware
        createdAt
        updatedAt
        tenant {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          createdAt
          updatedAt
        }
        partner {
          id
          enabled
          logo
          shortCode
          isPartner
          partnershipStartDate
          isServiceManager
          nextItemSorting
          nextItemGroupSorting
          defaultPartnerCode
          companyName
          companyVat
          firstUserId
          firstRetailStoreId
          userCount
          moitoIotCount
          retailStoreCount
          cashRegisterCount
          rtUtilityInactive
          currentRtUtilityCategory
          partnerText
          partnerTosAccepted
          privacyPolicyAcceptedDate
          archismallUsername
          archismallPassword
          archismallActiveInvoicesLastDatetime
          archismallPassiveInvoicesLastDatetime
          isBetaTester
          partnerCashRegisterCounter
          partnerMoitoIotCounter
          extensions
          mobile
          email
          zipCode
          county
          municipality
          address
          country
          createdAt
          updatedAt
        }
        license {
          id
          name
          commercialName
          baseName
          erpItemId
          createdAt
          updatedAt
        }
        originalRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          createdAt
          updatedAt
        }
        renewedRetailStoreLicense {
          id
          retailStoreId
          licenseId
          partnerId
          tenantId
          validFrom
          validTo
          assignedToUser
          activatedAt
          number
          electronicCashRegisterId
          moitoIotCode
          moitoIotId
          level
          isFree
          autorenew
          orderConfirmationNotified
          firmware
          createdAt
          updatedAt
        }
        retailStore {
          id
          tenantId
          name
          extensions
          deleted
          partnerId
          zipCode
          county
          municipality
          address
          country
          latitude
          longitude
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;