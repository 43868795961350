import { combineReducers } from "redux";
import { GenerateSlice } from "../../../utils";

export const reducer = combineReducers({
  moitoiots: GenerateSlice("moitoiots").reducer,
  versions: GenerateSlice("moitoiotversions").reducer,
  modifiers: GenerateSlice("modifiers").reducer,
  variants: GenerateSlice("variants").reducer,
  variantgroups: GenerateSlice("variantgroups").reducer,
  moitoiot_operators: GenerateSlice("moitoiot_operators").reducer,
  variantvariantgroups: GenerateSlice("variantvariantgroups").reducer,
  variantmodifiers: GenerateSlice("variantmodifiers").reducer,
  discountsurcharges: GenerateSlice("discountsurcharges").reducer,
  receipts: GenerateSlice("receipts").reducer,
  logicalprinters: GenerateSlice("logicalprinters").reducer,
  physicalprinters: GenerateSlice("physicalprinters").reducer,
  itemlogicalprinters: GenerateSlice("itemlogicalprinters").reducer,
  itemvariantgroups: GenerateSlice("itemvariantgroups").reducer,
  barcodes: GenerateSlice("barcodes").reducer,
  payments: GenerateSlice("payments").reducer,
  phases: GenerateSlice("phases").reducer,
  tobaccos: GenerateSlice("tobaccos").reducer,
});
