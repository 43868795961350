import React, { useState, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import { isEqual, orderBy } from "lodash";
import { GetText } from "../functions/GetText";

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const getValueFromStringPath = (obj, path, getText = false) => {
  return path.split(".").reduce(function (o, k) {
    if (getText) {
      return GetText(o && o[k]);
    }
    return o && o[k];
  }, obj);
};

export function LookupField({
  label,
  getText = false,
  onChange,
  data = [],
  valueField = "id",
  labelField = "name",
  defaultValue = undefined,
  queryFilter = null,
  query,
  topOption,
  isMulti = false,
  closeMenuOnSelect = true,
  disabled = false,
  sortFields = null,
  sortDirections = ["desc"],
  additionalParams = {},
  forceChangeValue = false,
  isClearable = true,
  required = false,
  graphql = "",
  isPublicApi = false,
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (
      isEqual(value, defaultValue) ||
      (defaultValue && (!value || Object.keys(value).length <= 0))
    ) {
      setValue(defaultValue);
    }

    if (forceChangeValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, value]);

  const loadOptions = async (search, prevOptions, { nextToken }) => {
    await sleep(500);

    var params = {
      limit: 20,
      nextToken: nextToken,
      ...additionalParams,
    };
    if (!labelField.includes(".")) {
      if ((query && query.toLowerCase().includes("search")) || (graphql && graphql.includes("search"))) {
        if (search) {
          const wildcards = search.toLowerCase().split(" ").map(el => "*" + el + "*");

          params.filter = {
            and: wildcards.map(wc => {
              return { [labelField]: { wildcard: wc } };
            }),
          };
        }
      }
      else {
        params.filter = {
          [labelField]: { contains: search },
        };
      }
    }
    if (queryFilter) {
      params.filter = { ...params.filter, ...queryFilter };
    }

    var additionalOptions = [];
    if (topOption && !prevOptions.length) {
      additionalOptions[0] = topOption;
    }
    if (data.length > 0) {
      const hasMore =
        prevOptions.length === 0 && data.length > 20
          ? true
          : data.length > prevOptions.length + 20;
      const slicedData = data.slice(
        prevOptions.length,
        prevOptions.length + 20
      );
      
      return {
        options: slicedData.map((el) => {
          return {
            value: getValueFromStringPath(el, valueField),
            label: getValueFromStringPath(el, labelField, getText),
          };
        }),
        hasMore: hasMore,
      };
    } else {
      var promise;
      if (isPublicApi) {
        promise = API.graphql({ query: graphql || queries[query], variables: params, authMode: "AWS_IAM"});
      }
      else {
        promise = API.graphql(graphqlOperation(graphql || queries[query], params));
      }

      return promise
        .then((result) => {
          var options = additionalOptions.concat(
            result.data[query].items.map((el) => {
              var label = getValueFromStringPath(el, labelField, getText);
              if (!label || label === " ") {
                label = "-";
              }

              return {
                value: getValueFromStringPath(el, valueField),
                label: label,
              };
            })
          );
          if (sortFields) {
            options = additionalOptions.concat(
              orderBy(result.data[query].items, sortFields, sortDirections).map(
                (el) => {
                  var label = getValueFromStringPath(el, labelField, getText);
                  if (!label || label === " ") {
                    label = "-";
                  }

                  return {
                    value: getValueFromStringPath(el, valueField),
                    label: label,
                  };
                }
              )
            );
          }

          return {
            options: options,
            hasMore: !!result.data[query].nextToken,
            additional: {
              nextToken: result.data[query].nextToken,
            },
          };
        })
        .catch((e) => {
          return {
            options: additionalOptions.concat([{}]),
            hasMore: true,
            additional: {
              nextToken: null,
            },
          };
        });
    }
  };

  const onSelectChange = (newValue) => {
    if (isMulti) {
      onChange(newValue ? newValue.map((el) => el.value) : null);
    } else {
      onChange(newValue ? newValue.value : null);
    }

    //Because of useEffect logic there should be at least one key to make isClearable works
    //This component need a rework but It's used on too many points
    setValue(newValue || { mock: true });
  };

  return (
    <>
      {label && <label>{label}</label>}
      {required && (
        <>
          {" "}
          <strong className="text-danger">*</strong>
        </>
      )}
      <AsyncPaginate
        value={value}
        loadOptions={loadOptions}
        onChange={onSelectChange}
        additional={{
          nextToken: null,
        }}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={required ? false : isClearable}
        isDisabled={disabled}
      />
    </>
  );
}
