import { Auth, API } from "aws-amplify";
import * as congnitoRest from "../cognito/crud"

export function bulkTenantCreation(partnerId, storeId, userId, data) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/bulk_tenant_creation";
    let myInit = {
      body: {
        partnerId: partnerId,
        storeId: storeId,
        userId: userId,
        data: data,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function approveLicenseRequest(tenantId, id, userId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/approve_licenserequest";
    let myInit = {
      body: {
        tenantId: tenantId,
        id: id,
        userId: userId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function approvePartnershipRequest(id) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/approve_partnershiprequest";
    let myInit = {
      body: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function completeRegistration(params) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/complete_registration";
    let myInit = {
      body: { ...params },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function upgradePlan(partnerId, storeId, userId, newPlanValue) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/upgrade_plan";
    let myInit = {
      body: {
        partnerId: partnerId,
        storeId: storeId,
        userId: userId,
        newPlanValue: newPlanValue,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function importPrices(tenantId, fromPriceList, toPriceLists) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/import_prices";
    let myInit = {
      body: {
        tenantId: tenantId,
        fromPriceList: fromPriceList,
        toPriceLists: toPriceLists,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function requestMoitoIotLicense(data) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/request_moitoiot_license";
    let myInit = {
      body: {
        ...data,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function requestMoitoIotUpdateSublicenses(tenantId, moitoIotId, data) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/update_moitoiot_sublicenses";
    let myInit = {
      body: {
        tenantId: tenantId,
        moitoIotId: moitoIotId,
        data: data,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function migrateData(tenantId, type, moitoIotId, newCode) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/migrate_data";
    let myInit = {
      body: {
        tenantId: tenantId,
        service: type,
        id: moitoIotId,
        newCode: newCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function deleteUser(tenantId, userId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/delete_user";
    let myInit = {
      body: {
        tenantId: tenantId,
        userId: userId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function deleteData(userId, retailStoreId, tenantId, tables) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/delete_data";
    let myInit = {
      body: {
        userId: userId,
        retailStoreId: retailStoreId,
        tenantId: tenantId,
        tables: tables,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function requestAccountDeactivation(userId, tenantId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/request_account_deactivation";
    let myInit = {
      body: {
        userId: userId,
        tenantId: tenantId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function checkEcrNumber(number) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/check_ecr_number";
    let myInit = {
      body: {
        number: number,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function checkMoitoIotCode(code) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/check_moitoiot_code";
    let myInit = {
      body: {
        code: code,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function requestRTUtilityLicense(partnerId, storeId, userId, number) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/request_rtutility_license";
    let myInit = {
      body: {
        partnerId: partnerId,
        retailStoreId: storeId,
        userId: userId,
        number: number,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function checkShortCode(shortCode) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/check_shortcode";
    let myInit = {
      body: {
        shortCode: shortCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function checkLicenseExistance(licenseId, code) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/check_license_existance";
    let myInit = {
      body: {
        licenseId: licenseId,
        code: code,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function generatePreSignedURL(tenantId, key, func) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/generate_presigned_url";
    let myInit = {
      body: {
        tenantId: tenantId,
        s3Key: key,
        function: func,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function isLicenseRequestable(tenantId, retailStoreId, licenseId, otherParams = {}) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/is_license_requestable";
    let myInit = {
      body: {
        tenantId: tenantId,
        retailStoreId: retailStoreId,
        licenseId: licenseId,
        ...otherParams,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function checkVatNumber(tenantId, companyVat) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/check_vatnumber";
    let myInit = {
      body: {
        id: tenantId,
        companyVat: companyVat,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function getAppSettings() {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/get_appsettings";
    let myInit = {
      body: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function autocompleteRegistration(companyVat, number, code) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/autocomplete_registration";
    let myInit = {
      body: {
        companyVat: companyVat,
        number: number,
        code: code,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function translateAll(tenantId, entity, recordId = null, fields = []) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/translate_all";
    let myInit = {
      body: {
        tenantId: tenantId,
        entity: entity,
        recordId: recordId,
        fields: fields,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function translate(tenantId, text) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/translate";
    let myInit = {
      body: {
        tenantId: tenantId,
        text: text,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function getUser(userId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/get_user";
    let myInit = {
      body: {
        userId: userId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function createFidelityCard(params) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/create_loyalty_card";
    let myInit = {
      body: {
        ...params
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function listS3Objects(prefix, tenantId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/list_s3_objects";
    let myInit = {
      body: {
        prefix: prefix,
        tenantId: tenantId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function createAccountant(params) {
  return congnitoRest.createCognitoUser(params.email, ["Users", "Accountants"])
  .then((cognitoUser) => {
    return Auth.currentSession().then((currentSession) => {
      let apiName = "DTRServiceApi";
      let path = "/dtrservice/create_accountant";
      let myInit = {
        body: {
          ...params,
          cognitoUsername: cognitoUser.Username,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
        },
      };

      return API.post(apiName, path, myInit);
    });
  });
}

export function manageAccountantTenants(params) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/manage_accountant_tenants";
    let myInit = {
      body: {
        ...params
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function sendEventNotification(tenantId, eventId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/send_event_notification";
    let myInit = {
      body: {
        tenantId: tenantId,
        eventId: eventId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function toggleAutorenew(id) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/toggle_autorenew";
    let myInit = {
      body: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function createEjProgramming(number, type, tenantId, userId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/create_ej_programming";
    let myInit = {
      body: {
        tenantId,
        userId,
        number,
        type
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function setOldEjExpDate(datetime) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/set_old_ej_exp_date";
    let myInit = {
      body: {
        datetime,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function canCreateEjProgramming(number, tenantId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/can_create_ej_programming";
    let myInit = {
      body: {
        tenantId,
        number,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function createOpticalFromXml(params) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/create_optical_from_xml";
    let myInit = {
      body: {
        ...params
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function createOpticalFile(tenantId, retailStoreId, data) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/create_optical_file";
    let myInit = {
      body: {
        tenantId,
        retailStoreId,
        data
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function sendOpticalFile(recordId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/send_optical_file";
    let myInit = {
      body: {
        id: recordId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function checkUserExistance(email) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/check_user_existance";
    let myInit = {
      body: {
        email: email,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function deleteS3Object(tenantId, s3Key) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/delete_s3_object";
    let myInit = {
      body: {
        tenantId: tenantId,
        s3Key: s3Key,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function generateLicensesByDealer() {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/generate_licenses_by_dealer";
    let myInit = {
      body: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function setFilePublic(tenantId, s3Key) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/set_file_public";
    let myInit = {
      body: {
        tenantId,
        s3Key,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function downloadEnowebItems(tenantId, retailStoreId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/download_enoweb_items";
    let myInit = {
      body: {
        tenantId,
        retailStoreId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function downloadOpticalFiles(tenantId, id) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/download_optical_files";
    let myInit = {
      body: {
        tenantId,
        id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function deleteEnowebItems(tenantId, retailStoreId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/delete_enoweb_items";
    let myInit = {
      body: {
        tenantId,
        retailStoreId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}


export function saveTobaccoFile(record) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/save-tobacco-file";
    let myInit = {
      body: {
        ...record,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function deleteTobacco(id) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/delete-tobacco";
    let myInit = {
      body: {
        id: id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function sendPendingEventNotification(id, operation) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/send-pending-event-notification";
    let myInit = {
      body: {
        id: id,
        operation: operation,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function copyItemAssociations(tenantId, sourceItemId, destinationItemId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/copy-item-associations";
    let myInit = {
      body: {
        tenantId: tenantId,
        sourceItemId: sourceItemId,
        destinationItemId: destinationItemId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}


export function currentLocationSorting(retailStoreId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/current-location-sorting";
    let myInit = {
      body: {
        retailStoreId: retailStoreId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}


export function rearrangeRecords(tableName, retailStoreId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/rearrange-records";
    let myInit = {
      body: {
        tableName: tableName,
        retailStoreId: retailStoreId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function triggerMoitoIotMessages(tenantId, tableName, recordId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/trigger-moitoiot-messages";
    let myInit = {
      body: {
        tenantId: tenantId,
        tableName: tableName,
        recordId: recordId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}

export function getCashRegisterTags(partnerId) {
  return Auth.currentSession().then((currentSession) => {
    let apiName = "DTRServiceApi";
    let path = "/dtrservice/get-cash-register-tags";
    let myInit = {
      body: {
        partnerId: partnerId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${currentSession.getAccessToken().getJwtToken()}`,
      },
    };

    return API.post(apiName, path, myInit);
  });
}