import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { actions } from "../_redux/actions";
import { connect } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../../_metronic/_partials/dropdowns";
import { FormattedMessage } from "react-intl";
import { GetTenant } from "../../../utils";

class StoreSelectorDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      store: null,
    };
  }

  componentDidMount() {
    let store = undefined;

    if (
      this.props.currentStoreId === "" ||
      this.props.currentStoreId === undefined
    ) {
      let storeOb = this.props.userRetailStores.find(
        (i) => i.retailStoreId === this.props.retailStoreDefault
      );
      store = null;
      if (storeOb) {
        store = storeOb.retailStore;
      }
    } else {
      let storeOb = this.props.userRetailStores.find(
        (i) => i.retailStoreId === this.props.currentStoreId
      );
      store = null;
      if (storeOb) {
        store = storeOb.retailStore;
      }
    }

    this.setState({
      store: store,
    });
  }

  render() {
    return (
      <>
        {this.state.store && this.props.tenant && (
          <Dropdown drop="down">
            <Dropdown.Toggle
              as={DropdownTopbarItemToggler}
              id="dropdown-toggle-my-cart"
              className="w-100"
            >
              <OverlayTrigger
                placement="bottom"
                className="w-100"
                overlay={
                  <Tooltip id="language-panel-tooltip">
                    <FormattedMessage id="SERVICE.SELECT_STORE" />
                  </Tooltip>
                }
              >
                <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 p-2 w-100 text-center">
                  <div className="bg-secondary rounded p-2 w-100 text-center text-dark">
                    {this.state.store.name}
                  </div>
                </div>
              </OverlayTrigger>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
              <ul className="navi navi-hover py-4">
                {this.props.userRetailStores
                  .filter(
                    (el) => el.retailStore.tenantId === this.props.tenant.id
                  )
                  .map((userStore) => (
                    <li key={userStore.id} className="navi-item">
                      <a
                        href="/#"
                        onClick={() =>
                          this.props.setCurrentStore(userStore.retailStore.id)
                        }
                        className="navi-link"
                      >
                        <span className="navi-text">
                          {userStore.retailStore.name}
                        </span>
                      </a>
                    </li>
                  ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: GetTenant(),
    retailStoreDefault: state.auth.user
      ? state.auth.user.retailStoreDefault
      : "",
    currentStoreId: state.store.currentStoreId,
    userRetailStores: state.auth.user
      ? state.auth.user.userRetailStore.items
      : [],
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        setCurrentStore: actions.setCurrentStore,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreSelectorDropdown);
