import React, { useState, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import { isEqual } from "lodash";

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

const getValueFromStringPath = (obj, path) => {
  return path.split(".").reduce(function (o, k) {
    return o && o[k];
  }, obj);
};

export function CustomizableLookupField({
  label,
  onChange,
  data = [],
  valueField = "id",
  labelField = "name",
  defaultValue = undefined,
  queryFilter = null,
  query,
  topOption,
  disabled = false,
  additionalParams = {},
  onInputChange,
  forceChangeValue = false,
  required = false,
}) {
  const [value, setValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (
      isEqual(value, defaultValue) ||
      (defaultValue && (!value || Object.keys(value).length <= 0))
    ) {
      setValue(defaultValue);
    }
    if (forceChangeValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, value, forceChangeValue]);

  const loadOptions = async (search, prevOptions, { nextToken }) => {
    await sleep(500);

    const params = {
      limit: 20,
      nextToken: nextToken,
      ...additionalParams,
    };
    if (!labelField.includes(".")) {
      if (query && query.toLowerCase().includes("search")) {
        if (search) {
          const wildcards = search.toLowerCase().split(" ").map(el => "*" + el + "*");

          params.filter = {
            and: wildcards.map(wc => {
              return { [labelField]: { wildcard: wc } };
            }),
          };
        }
      }
      else {
        params.filter = {
          [labelField]: { contains: search },
        };
      }
    }
    if (queryFilter) {
      params.filter = { ...params.filter, ...queryFilter };
    }

    var additionalOptions = [];
    if (topOption && !prevOptions.length) {
      additionalOptions[0] = topOption;
    }
    if (data.length > 0) {
      const hasMore =
        prevOptions.length === 0
          ? false
          : data.length > prevOptions.length + 20;
      var filteredData = data.filter(
        (el) =>
          el[labelField].toLowerCase().includes(search) ||
          el[labelField].toUpperCase().includes(search)
      );
      const slicedData = filteredData.slice(
        prevOptions.length,
        prevOptions.length + 20
      );

      return {
        options: slicedData.map((el) => {
          return {
            value: getValueFromStringPath(el, valueField),
            label: getValueFromStringPath(el, labelField),
          };
        }),
        hasMore: hasMore,
      };
    } else {
      return API.graphql(graphqlOperation(queries[query], params))
        .then((result) => {
          return {
            options: additionalOptions.concat(
              result.data[query].items.map((el) => {
                return {
                  value: getValueFromStringPath(el, valueField),
                  label: getValueFromStringPath(el, labelField),
                };
              })
            ),
            hasMore: !!result.data[query].nextToken,
            additional: {
              nextToken: result.data[query].nextToken,
            },
          };
        })
        .catch((e) => {
          return {
            options: additionalOptions.concat([{}]),
            hasMore: true,
            additional: {
              nextToken: null,
            },
          };
        });
    }
  };

  const onSelectChange = (newValue) => {
    onChange(newValue ? newValue : null);
    setValue(newValue);

    setInputValue("");
  };

  const customOnInputChange = (newInputValue, { action }) => {
    if (action === "input-change") {
      setInputValue(newInputValue);

      if (onInputChange) {
        onInputChange(newInputValue);
      }
    }
  };

  return (
    <>
      {label && <label>{label}</label>}
      {required && (
        <>
          {" "}
          <strong className="text-danger">*</strong>
        </>
      )}
      <div>
        <AsyncPaginate
          value={value}
          loadOptions={loadOptions}
          onChange={onSelectChange}
          inputValue={inputValue}
          onInputChange={customOnInputChange}
          isClearable={true}
          isDisabled={disabled}
          disabled={disabled}
          additional={{
            nextToken: null,
          }}
        />
      </div>
    </>
  );
}
