import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { GetValueFromStringPath } from "../../functions/GetValueFromStringPath";

export function DeleteDialog({ id, show, onHide, UIContext, hardDelete = false }) {
  const intl = useIntl();

  const UIProps = useMemo(() => {
    return {
      setIds: UIContext.setIds,
      queryParams: UIContext.queryParams,
      sendToastNotification: UIContext.sendToastNotification,
      statePath: UIContext.statePath,
      actions: UIContext.actions,
    };
  }, [UIContext]);

  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: GetValueFromStringPath(state, UIProps.statePath + ".actionsLoading"),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteRecord = () => {
    var promise;

    if (!hardDelete) {
      const newRecord = {
        id: id,
        deleted: true,
      };
      promise = dispatch(UIProps.actions.updateObject(newRecord));
    }
    else {
      promise = dispatch(UIProps.actions.deleteObject(id));
    }

    promise
      .then(() => {
        UIProps.sendToastNotification(
          "success",
          intl.formatMessage({ id: "SERVICE.RECORD_HAS_BEEN_DELETED" })
        );
        UIProps.setIds([]);
        onHide();
      })
      .catch(() => {
        UIProps.sendToastNotification(
          "error",
          intl.formatMessage({ id: "SERVICE.ERROR_DELETING_RECORD" })
        );
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title >
          <FormattedMessage id="SERVICE.DELETE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <FormattedMessage id="SERVICE.SURE_TO_DELETE" />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            <FormattedMessage id="SERVICE.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteRecord}
            className="btn btn-danger btn-elevate"
          >
            <FormattedMessage id="SERVICE.DELETE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
