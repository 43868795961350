import * as Yup from "yup";
import * as restApi from "../../modules/Admin/rest/api";
import { YupCacheValidation } from "./YupCacheValidation";

const checkOnDb = async (value, checkExistance) => {
  return new Promise((resolve, reject) => {
    if (value && (value.length === 16 || value.length === 15)) {
      restApi.checkMoitoIotCode(value)
        .then(result => {
          if (result?.data?.exists) {
            resolve(checkExistance ? false : true); //ugly but understandable
          }
          else {
            resolve(checkExistance ? true : false);
          }
        })
        .catch((error) => {
          resolve(checkExistance ? false : true);
        });
    }
    else {
      resolve(checkExistance ? false : true);
    }
  });
}

export function MoitoIOTCodeValidator(
  dispatch,
  intl,
  required = true,
  checkExistance = true,
  checkNotExistance = false,
) {
  var result = Yup.string()
                .test('checkLength', intl.formatMessage({ id: "MOITO_IOT.INVALID_LENGTH" }), val => val && (val.length === 16 || val.length === 15))
                .test('checkSlash', intl.formatMessage({ id: "MOITO_IOT.SLASH_IN_CODE_MESSAGE" }), val => val && !val.includes("/") && !val.includes("\\"))
                .test('checkSpaces', intl.formatMessage({ id: "MOITO_IOT.INVALID_CODE" }), val => val && !val.includes(" "))
                .test('checkTemp', intl.formatMessage({ id: "MOITO_IOT.TEMP_IN_CODE_MESSAGE" }), val => val && !val.includes("TEMP_"));

  if (checkExistance || checkNotExistance) {
    const checkOnDbTest = YupCacheValidation((value) => checkOnDb(value, checkExistance));

    result = result.test(
      "checkMoitoIOTCodeExistance",
      intl.formatMessage({ id: checkExistance ? "MOITO_IOT.CODE_EXISTANCE_ERROR" : "MOITO_IOT.CODE_NOT_EXISTANCE_ERROR" }),
      checkOnDbTest,
    );
  }

  if (required) {
    result = result.required(
      intl.formatMessage({ id: "MOITO_IOT.CODE_IS_MANDATORY" })
    );
  }

  return result;
}
